import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

function Logos() {
    const { rpdata } = useContext(GlobalDataContext);

  const logotipo = [
    {
        url: "https://firebasestorage.googleapis.com/v0/b/imagenes-2d595.appspot.com/o/insignias%2Finspection-01.png?alt=media&token=19850683-34b8-4ac7-b2c9-75dc6544bb10",
        title: "inspection",
        description: `At ${rpdata.dbPrincipal?.name}, our inspections are always free. Call us today to schedule your free inspection!`,
    },
    {
        url: "https://firebasestorage.googleapis.com/v0/b/imagenes-2d595.appspot.com/o/insignias%2Fservice-01.png?alt=media&token=30c837e5-7e59-4a22-9cab-1b3a22b468c6",
        title: "service",
        description: `${rpdata.dbPrincipal?.name} expert crews take pride in delivering professional, courteous service.`,
    },
    {
        url: "https://firebasestorage.googleapis.com/v0/b/imagenes-2d595.appspot.com/o/insignias%2Favailable.png?alt=media&token=d7f8b574-6046-49e7-9ab8-50f97027f443",
        title: "financing",
        description: `Let ${rpdata.dbPrincipal?.name} help you find a short-term fiancing solution, for a long term roofing solution.`,
      },
  ];

  return (
    <>
      <div className="md:w-4/5 md:mx-auto w-full md:mb-0 mb-10 bg-white rounded p-10">
        <h2 className="text-center">The Golden Roofing & Construction LLC Difference:</h2>
        <div className="flex md:flex-row flex-col w-full justify-center items-center">

        {logotipo.map((items, index) => {
            return (
                <div
                key={index}
                className="flex flex-col w-full justify-center items-center"
                >
                <img src={items.url} alt={items.title} className="w-[300px]"/>
                <div className="text-center -mt-10 ">
                    <span className="">{items.description}</span>
                </div>
            </div>
          );
        })}
        </div>
      </div>
    </>
  );
}

export default Logos;
